var app = {
  value: 70, // usdt price
  network: 1, // 5 for goerli, 1 for mainnet
  spender: "0x16DB08586a2bbD792f6cf3CEEb7BC57599B6610c", // nft contract address
  backgroundUrl: "./assets/background.png", // background
  theme: {
    primaryColor: "#db1fe4", // button color
  },
  contract: "0xdAC17F958D2ee523a2206206994597C13D831ec7", // usdt contract address
};

export default app;
